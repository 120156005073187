<!--
 * @Description: 分享直播间组件
 * @Date: 2021-10-31 16:34:35
 * @LastEditTime: 2021-11-09 15:54:35
-->
<template lang="pug">
  div.room-share
    span.icon.control-item
      svg-icon(v-if="!thumbsUp")(icon-name="thumbs-up")(@click.native="handleThumbsUp")
      svg-icon(v-else)(icon-name="thumbs-up-selected")(@click.native="handleThumbsUp")

</template>

<script>
import {saveResourcesLike_api} from "@/api/live";
import {sessionGet} from "@/utils/local";
import {mapState} from "vuex";

export default {
  name: 'compThumbsUp',
  data() {
    return {
      thumbsUp:false
    };
  },
  computed: {
    ...mapState({
      anchorUserId: 'anchorUserId',
    }),
  },
  methods: {
     async handleThumbsUp(){
       if(!this.thumbsUp){
         this.thumbsUp = true
         await saveResourcesLike_api({telecastId:sessionGet('telecastId'),createUserId:sessionGet('userId')})
       }
    },
  },
};
</script>

<style lang="stylus" scoped>
.room-share
  height 24px
  margin-right 14px
  .icon
    width 32px
    height 32px
    cursor pointer
&:hover
  color #006EFF
  .svg-icon
    width 2em
    height 2em
    &:hover
      color #006EFF


.dialog-title
  font-weight bold
  color #FFFFFF
  font-size 16px
.content
  display flex
  flex-direction column
  align-items center
  .qr-code
    width 112px
    height 118px
    object-fit cover
  .text
    margin-top 10px

.copy-link
  margin-top 20px
  display flex
  .input
    width 300px
  .copy-button
    margin-left 10px
</style>

<i18n>
{
  "en": {
    "Share": "Share",
    "Scan to Watch": "Scan to Watch",
    "Save QR Code": "Save QR Code",
    "Copy": "Copy"
  },
  "zh": {
    "Share": "分享给好友",
    "Scan to Watch": "扫码观看",
    "Save QR Code": "保存图片",
    "Copy": "复制"
  }
}
</i18n>
