<!--
 * @Description: 退出直播间
 * @Date: 2021-10-28 11:07:40
 * @LastEditTime: 2022-01-26 18:23:24
-->
<template lang="pug">
  div.exit-container(@click="handleExit")
    span.exit
      //svg-icon.icon(icon-name="exit")
      el-button(type="primary" size="mini") 退出
    //span {{ $t('Exit') }}

    //- 确认结束直播的弹窗
    el-dialog.dialog-style(
      :visible.sync="showExitDialog"
      width="340px"
      center=true
      append-to-body=true
    )
      span.dialog-title(slot="title") {{ $t('Note') }}
      div.content-info {{ $t('Are you sure you want to exit the room') }}
      span.dialog-footer(slot="footer")
        el-button(@click="showExitDialog = false") {{ $t('common.Cancel') }}
        el-button(type="primary" @click="handleExitLive") {{ $t('common.Yes') }}
</template>

<script>

export default {
  name: 'compExit',
  data() {
    return {
      showExitDialog: false,
      userInfo: '',
    };
  },
  methods: {
    // 退出房间
    handleExit() {
      this.showExitDialog = true;
    },
    // 确认退出直播间
    handleExitLive() {
      this.$eventBus.$emit('exit');
      this.$eventBus.$emit('logout');

      let url = window.location.href

      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.$router.go(-1)
      } else {
        if (url.includes('device=mobile')) {
          window.close()
        } else {
          this.$router.go(-1)
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@media (hover: hover)
  .exit
    cursor pointer
    margin-right 0.3em

    &:hover
      color #006EFF

.exit-container
  display flex;
  align-items center
</style>

<i18n>
{
  "en": {
    "Note": "Note",
    "End": "End",
    "Are you sure you want to exit the room": "Are you sure you want to exit the room?",
    "Exit": "exit"
  },
  "zh": {
    "Note": "温馨提示",
    "End": "结束直播",
    "Are you sure you want to exit the room": "确认退出直播间吗?",
    "Exit": "退出"
  }
}
</i18n>
