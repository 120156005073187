<template lang="pug">
  div#ap(:class="[layoutClassName]" ref="app")
    div#header
      comp-header(v-if="!$isMobile")
      comp-header-mobile(v-if="$isMobile")
    div#content
      div#player
        comp-player(v-if="!$isMobile" ref="player")
        comp-player-mobile(v-if="$isMobile" ref="player")
      div#message
        comp-message(ref="message"
          :isMobileVerticalLayout="isMobileVerticalLayout"
          :isMobileHorizontalLayout="isMobileHorizontalLayout")
</template>

<script>
// import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import compHeader from '@/components/comp-header/index';
import compHeaderMobile from '@/components/comp-header/index-mobile';
import compPlayer from '@/components/comp-player/index';
import compPlayerMobile from '@/components/comp-player/index-mobile';
import compMessage from '@/components/comp-message';
import layout from '@/layout.js';
import '@/assets/icons/index.js';
import {
  SET_ANCHOR_USER_ID,
  SET_IS_SUPPORT_WEBRTC,
  SET_PLAYER_DOMAIN,
  SET_ROOM_ID,
  SET_ROOM_NAME,
  SET_SDK_APP_ID,
  SET_USER_SIG,
  UPDATE_LINE_TYPE,
  UPDATE_USER_INFO,
} from '@/constants/mutation-types';
import {LINE_TYPE,} from '@/constants/room';
import {sdkAppId,} from '@/config/basic-info-config';
import {mapState} from 'vuex';
import TRTC from 'trtc-js-sdk';
import {sessionGet, sessionSet} from "@/utils/local";
import {getUserSig, getYunTelecast_api, saveTelecastTeacherHour_api} from '@/api/live';
import {
  dateFormat
} from "utils/dateFormat";
// import {userInfo} from "../../config/basic-info-config";
import jsCookie from "js-cookie";

export default {
  name: 'App',
  mixins: [layout],
  data() {
    return {
      timerId: '', //计时器id
      timer: 0, //计时器
      userInfos: '',
      telecastId: '',
      userInfo: {
        userId: '',
        // 用户昵称
        userName: '',
        // 用户头像
        userAvatar: '',
      },
      // courseInfo:{},//直播课信息
      playerDomain: '',
      anchorUserInfo: {
        userId: '',
        // 用户昵称
        userName: '',
        // 用户头像
        userAvatar: '',
      },
      roomInfo: {
        roomId: '',
        roomName: '',
      }
    };
  },
  computed: {
    ...mapState({
      // userInfo: 'userInfo',
      roomId: 'roomId',
      userId: 'userId',
      // sdkAppId:'sdkAppId',
      // expireTime:'expireTime',
      // secretKey:'secretKey',
      // playerDomain:'playerDomain',
      // anchorUserInfo:'anchorUserInfo'
    }),
  },
  mounted() {
    console.log(this.$isMobile)
    this.watchTabUnload();
  },
  components: {
    compHeader,
    compHeaderMobile,
    compPlayer,
    compPlayerMobile,
    compMessage,
  },
  methods: {
    /* 视频计时*/
    async saveTelecastTeacherHour() {
      var that = this;
      saveTelecastTeacherHour_api({
        resourcesId: that.telecastId,
        length: that.timer,
        date: dateFormat('yyyy-MM-dd hh:mm:ss'),
        userId: that.userInfos.user_id
      });
    },
    async watchTabUnload() {
      var that = this;
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (that.userInfos.role_name == 'teacher') {
          that.saveTelecastTeacherHour();
          //liveEnd({"telecastStartId": that.telecastId});
        }
        var timer2 = setInterval(function () {
          that.fun1();
          clearInterval(timer2);
        }, 1000);     //在点击取消关闭页面按钮时启动计时器，执行一次就暂停
        if (e) {
          e.returnValue = '关闭提示';
        }
        return '关闭提示';
      };
      window.addEventListener("beforeunload", function (event) {
        event.returnValue = "关闭提示";
      });
    },
    fun1() {
      console.log('取消刷新')
    },
    // 处理是否支持 webRTC
    async handleSupportWebRTC() {
      const supportResult = await TRTC.checkSystemRequirements();
      const {isWebRTCSupported, isH264DecodeSupported} = supportResult.detail;
      if (!isWebRTCSupported || !isH264DecodeSupported) {
        this.$store.commit(SET_IS_SUPPORT_WEBRTC, false);
        this.$store.commit(UPDATE_LINE_TYPE, LINE_TYPE.CDN);
      }
    },
    // 处理页面数据
    async handlePlayerInfo() {
      let userSig = await this.getUserSig().then(res => {
        return res.data
      })

      this.$store.commit(SET_USER_SIG, userSig);
      this.$store.commit(SET_PLAYER_DOMAIN, this.playerDomain);
      this.$store.commit(SET_ROOM_ID, this.roomInfo.roomId);
      this.$store.commit(SET_ROOM_NAME, this.roomInfo.roomName);
      this.$store.commit(SET_ANCHOR_USER_ID, this.anchorUserInfo.userId);

      this.$store.commit(UPDATE_USER_INFO, {
        userId: this.userInfo.userId,
        userName: this.userInfo.userName,
        userAvatar: this.userInfo.userAvatar,
      });
      if (this.userInfos.role_name == 'teacher') {
        /*开始直播计时*/
        this.timer = 0;
        this.timerId = setInterval(() => {
          this.timer++
        }, 1000)
      }
    },
    // 退出直播间
    async handleExit() {
      // todo: 处理退出直播间
      var that = this;
      if (this.userInfos.role_name == 'teacher') {
        that.saveTelecastTeacherHour();
        clearInterval(this.timerId);
      }
    },
    // 退出登录
    async handleLogout() {
      // todo: 处理退出登录
      // saveTelecastTeacherHour_api({
      //   resourcesId: this.$route.query.telecastId,
      //   length: this.timer,
      //   date: dateFormat('yyyy-MM-dd hh:mm:ss'),
      //   userId: this.userInfo.userId
      // });
      // clearInterval(this.timerId);
    },
    async getUserSig() {
      return getUserSig({userId: this.userInfo.userId});
    },
    //查询云直播
    async getYunTelecast(id) {
      await getYunTelecast_api({telecastId: id}).then(res => {
        if (res.code == 200) {
          this.roomInfo.roomId = res.data.remarks1
          this.roomInfo.roomName = res.data.title
          this.anchorUserInfo.userId = res.data.createUserId
          this.anchorUserInfo.userName = res.data.userName
          this.anchorUserInfo.userAvatar = res.data.userUrl
          this.playerDomain = res.data.liveUrl
          // sessionSet()
          sessionSet("createUserId", this.anchorUserInfo.userId);
        }
      })
    }
  },
  /* 开始计时*/
  // async startPlay() {
  //    this.timer = 0;
  //    this.timerId = setInterval(() => {
  //      this.timer++
  //      console.log( this.timer)
  //    }, 1000)
  //  },

  async created() {
    // 注意：请优先设置 sdkAppId 信息
    this.$store.commit(SET_SDK_APP_ID, Number(sdkAppId));

    let userIn = sessionGet('userInfo') ? sessionGet('userInfo') : JSON.parse(jsCookie.get('userInfo'))
    this.userInfo.userId = userIn.user_id
    this.userInfo.userName = userIn.nick_name
    this.userInfo.userAvatar = userIn.avatar
    this.userInfos = userIn;
    let telecastId = this.$route.query.telecastId
    this.telecastId = telecastId;
    sessionSet("telecastId", telecastId)
    await this.getYunTelecast(telecastId)
    // 判断是否支持 webrtc, 不支持 webrtc 的网页采用【线路三：标准直播】播放，且不可切换线路
    await this.handleSupportWebRTC();
    // 处理页面数据
    await this.handlePlayerInfo();
    // 处理监听事件
    this.$eventBus.$on('exit', this.handleExit);
    this.$eventBus.$on('logout', this.handleLogout);
    this.$eventBus.$on('showLoginCard', this.handleShowLoginCard);
  },
  beforeDestroy() {
    this.$eventBus.$off('exit', this.handleExit);
    this.$eventBus.$off('logout', this.handleLogout);
    this.$eventBus.$off('showLoginCard', this.handleShowLoginCard);
    clearInterval(this.timerId);
    if (this.userInfos.role_name == 'teacher') {
      this.saveTelecastTeacherHour();
      //liveEnd({"telecastStartId": this.telecastId});
    }
    window.onbeforeunload = undefined;
  },
};
</script>
<style lang="stylus">
#ap
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  width 100%
  height 100vh
  position relative
  text-align center
  overflow auto

// PC页面布局
#ap.app-layout
  background-color $backgroundColor
  color $fontColor

  #header
    width 100%
    height 52px
    background-color $themeColor

  #content
    width 100%
    position absolute
    left 0
    bottom 0
    top 52px
    display flex

    #player
      height 100%
      flex-grow 1
      background-color $backgroundColor

    #message
      width 30%
      min-width 300px
      max-width 406px
      height 100%
      background-color $IMThemeColor

// 移动端竖屏布局
#ap.mobile-vertical-layout
  background-color $IMThemeColor
  color $fontColor
  display flex
  flex-direction column

  #header
    width 100%
    max-height 130px

  #content
    flex-grow 1
    display flex
    flex-direction column

    #player
      width 100%
      height 250px
      background-color $backgroundColor

    #message
      background-color $IMThemeColor
      flex-grow 1
      position relative

// 移动端横屏布局
#ap.mobile-horizontal-layout
  color $fontColor

  #header
    width 100%
    position absolute
    top 0
    left 0
    z-index 10
    transform translateZ(100px)

  #content
    width 100%
    height 100%
    position relative

    #player
      width 100%
      height 100%
      background-color $backgroundColor

    #message
      position absolute
      bottom 0
      left 0
      width 100%
      height 70%
      pointer-events none
      transform translateZ(100px)

</style>

